import React, { useContext, useState } from 'react';
import { FinanceContext } from '../context/FinanceContext';
import { ClientContext } from '../context/ClientContext';
import { PackageContext } from '../context/PackageContext';
import { Pie, Line } from 'react-chartjs-2';

function Payments() {
  const { payments, updatePayment } = useContext(FinanceContext);
  const { clients } = useContext(ClientContext);
  const { packages } = useContext(PackageContext);

  const [filterClient, setFilterClient] = useState('');
  const [filterPackage, setFilterPackage] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [partialPayment, setPartialPayment] = useState({ id: null, amount: '', note: '' });
  const [expandedRow, setExpandedRow] = useState(null);

  // Filtra i pagamenti
  const filteredPayments = payments.filter((payment) => {
    const client = clients.find((c) => c.id === parseInt(payment.clientId, 10));
    const packageDetails = packages.find((pkg) => pkg.id === parseInt(payment.packageId, 10));

    const matchesClient =
      !filterClient ||
      (client && `${client.name} ${client.surname}`.toLowerCase().includes(filterClient.toLowerCase()));

    const matchesPackage =
      !filterPackage ||
      (packageDetails &&
        `${packageDetails.name} (${packageDetails.type})`.toLowerCase().includes(filterPackage.toLowerCase()));

    const matchesStatus =
      !filterStatus ||
      (filterStatus === 'Pagato' &&
        (payment.status === 'Pagato' || payment.amount - (payment.remainingAmount || 0) > 0)) || // Include pagamenti parziali
      (filterStatus === 'Non Pagato' && payment.status === 'Non Pagato');

    const matchesDate =
      (!filterStartDate || new Date(payment.date) >= new Date(filterStartDate)) &&
      (!filterEndDate || new Date(payment.date) <= new Date(filterEndDate));

    return matchesClient && matchesPackage && matchesStatus && matchesDate;
  });

  // Calcolo dei totali
  const totalPaid = payments.reduce((sum, p) => {
    const partialPaid = p.amount - (p.remainingAmount || 0);
    return sum + (partialPaid > 0 ? partialPaid : 0);
  }, 0);

  const totalUnpaid = payments.reduce((sum, p) => sum + (p.remainingAmount || 0), 0);

  const filteredTotalPaid = filteredPayments.reduce((sum, p) => {
    const partialPaid = p.amount - (p.remainingAmount || 0);
    return sum + (partialPaid > 0 ? partialPaid : 0);
  }, 0);

  const filteredTotalUnpaid = filteredPayments.reduce((sum, p) => sum + (p.remainingAmount || 0), 0);

  const pieData = {
    labels: ['Pagato', 'Non Pagato'],
    datasets: [
      {
        data: [totalPaid, totalUnpaid],
        backgroundColor: ['#4CAF50', '#F44336'],
      },
    ],
  };

  const monthlyData = payments.reduce((acc, p) => {
    const date = new Date(p.date);
    if (!isNaN(date)) {
      const month = date.toLocaleString('default', { month: 'long' });
      acc[month] = acc[month] ? acc[month] + p.amount : p.amount;
    }
    return acc;
  }, {});
  const lineData = {
    labels: Object.keys(monthlyData),
    datasets: [
      {
        label: 'Incassi Mensili',
        data: Object.values(monthlyData),
        borderColor: '#4CAF50',
        backgroundColor: 'rgba(76, 175, 80, 0.2)',
        fill: true,
      },
    ],
  };

  const handlePartialPayment = (e) => {
    e.preventDefault();
    const payment = payments.find((p) => p.id === partialPayment.id);
    if (payment) {
      const partialAmount = parseFloat(partialPayment.amount);
      if (isNaN(partialAmount) || partialAmount <= 0) {
        alert('Inserisci un importo valido.');
        return;
      }

      const note = partialPayment.note;
      const newRemaining = (payment.remainingAmount || payment.amount) - partialAmount;
      const newStatus = newRemaining <= 0 ? 'Pagato' : 'Non Pagato';

      const updatedPayment = {
        ...payment,
        remainingAmount: newRemaining > 0 ? newRemaining : 0,
        status: newStatus,
        history: [
          ...(payment.history || []),
          {
            date: new Date().toISOString(),
            amount: partialAmount,
            note,
          },
        ],
      };

      updatePayment(updatedPayment);
      setPartialPayment({ id: null, amount: '', note: '' });
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Gestione Pagamenti</h2>

      {/* Totali */}
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg">
          <strong>Totale Pagato:</strong> €{totalPaid.toFixed(2)}
        </p>
        <p className="text-lg">
          <strong>Totale Non Pagato:</strong> €{totalUnpaid.toFixed(2)}
        </p>
      </div>

      {/* Totale Filtrato */}
      <div className="flex justify-between items-center mb-4">
        <p className="text-lg">
          <strong>Totale Pagato (Filtrato):</strong> €{filteredTotalPaid.toFixed(2)}
        </p>
        <p className="text-lg">
          <strong>Totale Non Pagato (Filtrato):</strong> €{filteredTotalUnpaid.toFixed(2)}
        </p>
      </div>

      {/* Filtri */}
      <div className="flex flex-wrap gap-4 mb-4">
        <input
          type="text"
          placeholder="Cerca cliente"
          value={filterClient}
          onChange={(e) => setFilterClient(e.target.value)}
          className="p-2 border rounded"
        />
        <input
          type="text"
          placeholder="Cerca pacchetto"
          value={filterPackage}
          onChange={(e) => setFilterPackage(e.target.value)}
          className="p-2 border rounded"
        />
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">Tutti gli stati</option>
          <option value="Pagato">Pagato</option>
          <option value="Non Pagato">Non Pagato</option>
        </select>
        <input
          type="date"
          value={filterStartDate}
          onChange={(e) => setFilterStartDate(e.target.value)}
          className="p-2 border rounded"
        />
        <input
          type="date"
          value={filterEndDate}
          onChange={(e) => setFilterEndDate(e.target.value)}
          className="p-2 border rounded"
        />
      </div>

      {/* Grafici */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="p-4 shadow-md" style={{ height: '300px', overflow: 'hidden' }}>
          <h3>Totale Incassato vs Non Incassato</h3>
          <Pie data={pieData} options={{ maintainAspectRatio: false }} />
        </div>
        <div className="p-4 shadow-md" style={{ height: '300px', overflow: 'hidden' }}>
          <h3>Andamento Mensile degli Incassi</h3>
          <Line data={lineData} options={{ maintainAspectRatio: false }} />
        </div>
      </div>

      {/* Tabella Pagamenti */}
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="bg-gray-200 text-center">
            <th className="p-2 border">Cliente</th>
            <th className="p-2 border">Pacchetto</th>
            <th className="p-2 border">Importo</th>
            <th className="p-2 border">Pagato</th>
            <th className="p-2 border">Saldo</th>
            <th className="p-2 border">Stato</th>
            <th className="p-2 border">Data</th>
            <th className="p-2 border">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayments.map((payment) => {
            const client = clients.find((c) => c.id === parseInt(payment.clientId, 10));
            const pkg = packages.find((p) => p.id === parseInt(payment.packageId, 10));
            const partialPaid = payment.amount - (payment.remainingAmount || 0);
            return (
              <React.Fragment key={payment.id}>
                <tr className="text-center">
                  <td className="p-2 border">
                    {client ? `${client.name} ${client.surname}` : 'Sconosciuto'}
                  </td>
                  <td className="p-2 border">{pkg ? `${pkg.name} (${pkg.type})` : 'Sconosciuto'}</td>
                  <td className="p-2 border">€{payment.amount.toFixed(2)}</td>
                  <td className="p-2 border">€{partialPaid.toFixed(2)}</td>
                  <td className="p-2 border">€{(payment.remainingAmount || 0).toFixed(2)}</td>
                  <td className="p-2 border">{payment.status}</td>
                  <td className="p-2 border">
                    {new Date(payment.date).toLocaleDateString()}
                  </td>
                  <td className="p-2 border">
                    <button
                      onClick={() => setExpandedRow(payment.id === expandedRow ? null : payment.id)}
                      className="px-4 py-2 bg-blue-500 text-white rounded"
                    >
                      {expandedRow === payment.id ? 'Chiudi' : 'Visualizza'}
                    </button>
                  </td>
                </tr>
                {expandedRow === payment.id && (
                  <tr>
                    <td colSpan={8} className="p-4 bg-gray-100">
                      <h4 className="text-lg font-semibold">Storia dei Pagamenti</h4>
                      <ul>
                        {payment.history?.map((entry, idx) => (
                          <li key={idx} className="mb-2">
                            <strong>Data:</strong>{' '}
                            {new Date(entry.date).toLocaleDateString()} -{' '}
                            <strong>Importo:</strong> €{entry.amount.toFixed(2)} -{' '}
                            <strong>Note:</strong> {entry.note || 'Nessuna'}
                          </li>
                        ))}
                      </ul>

                      {/* Form per Inserimento Nuovo Pagamento */}
                      <h4 className="text-lg font-semibold mt-4">Aggiungi un Nuovo Pagamento</h4>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          const partialAmount = parseFloat(partialPayment.amount);
                          if (isNaN(partialAmount) || partialAmount <= 0) {
                            alert('Inserisci un importo valido.');
                            return;
                          }

                          const note = partialPayment.note;
                          const newRemaining =
                            (payment.remainingAmount || payment.amount) - partialAmount;
                          const newStatus = newRemaining <= 0 ? 'Pagato' : 'Non Pagato';

                          const updatedPayment = {
                            ...payment,
                            remainingAmount: newRemaining > 0 ? newRemaining : 0,
                            status: newStatus,
                            history: [
                              ...(payment.history || []),
                              {
                                date: new Date().toISOString(),
                                amount: partialAmount,
                                note,
                              },
                            ],
                          };

                          updatePayment(updatedPayment);
                          setPartialPayment({ id: null, amount: '', note: '' });
                          alert('Pagamento aggiunto con successo!');
                        }}
                      >
                        <div className="flex flex-col md:flex-row items-center mt-4 gap-4">
                          <div className="flex-1">
                            <label className="block text-sm font-medium">Importo</label>
                            <input
                              type="number"
                              value={partialPayment.amount}
                              onChange={(e) =>
                                setPartialPayment({
                                  ...partialPayment,
                                  id: payment.id,
                                  amount: e.target.value,
                                })
                              }
                              className="p-2 w-full border rounded"
                              required
                            />
                          </div>
                          <div className="flex-1">
                            <label className="block text-sm font-medium">Note</label>
                            <textarea
                              value={partialPayment.note}
                              onChange={(e) =>
                                setPartialPayment({
                                  ...partialPayment,
                                  id: payment.id,
                                  note: e.target.value,
                                })
                              }
                              className="p-2 w-full border rounded"
                              rows="2"
                            />
                          </div>
                          <button
                            type="submit"
                            className="px-4 py-2 bg-green-500 text-white rounded mt-4 md:mt-0"
                          >
                            Aggiungi Pagamento
                          </button>
                        </div>
                      </form>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Payments;
