import React, { createContext, useState, useEffect } from 'react';

export const PackageContext = createContext();

export const PackageProvider = ({ children }) => {
  const [packages, setPackages] = useState(() => {
    const savedPackages = localStorage.getItem('packages');
    return savedPackages ? JSON.parse(savedPackages) : [];
  });

  useEffect(() => {
    localStorage.setItem('packages', JSON.stringify(packages));
  }, [packages]);

  const addPackage = (newPackage) => {
    setPackages((prev) => [...prev, newPackage]);
  };

  const removePackage = (id) => {
    setPackages((prev) => prev.filter((pkg) => pkg.id !== id));
  };

  return (
    <PackageContext.Provider value={{ packages, addPackage, removePackage }}>
      {children}
    </PackageContext.Provider>
  );
};
