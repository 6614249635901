import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ClientContext } from '../../context/ClientContext';
import { AppointmentContext } from '../../context/AppointmentContext';

function ClientProfile() {
  const { clientId } = useParams();
  const { clients, removeClient } = useContext(ClientContext);
  const { appointments, removeAppointment } = useContext(AppointmentContext);

  const [client, setClient] = useState(null);

  useEffect(() => {
    const foundClient = clients?.find((c) => c.id === parseInt(clientId));
    setClient(foundClient);
  }, [clients, clientId]);

  const handleDeleteClient = () => {
    if (window.confirm(`Sei sicuro di voler eliminare il cliente ${client.name}?`)) {
      removeClient(client.id);
    }
  };

  if (!client) return <p>Caricamento...</p>;

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold mb-4">
        {client.name} {client.surname}
      </h2>
      <button
        onClick={handleDeleteClient}
        className="px-4 py-2 bg-red-600 text-white rounded"
      >
        Elimina Cliente
      </button>
      <Link
        to={`/modifica-cliente/${client.id}`}
        className="ml-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Modifica Cliente
      </Link>

      <h3 className="text-2xl mt-4">Dettagli Cliente</h3>
      <p><strong>Email:</strong> {client.email}</p>
      <p><strong>Telefono:</strong> {client.phone}</p>
      <p><strong>Indirizzo:</strong> {client.address}, {client.city}</p>

      <h3 className="text-2xl mt-4">Figli</h3>
      <ul>
        {client.children?.map((child, index) => (
          <li key={index}>
            {child.name}, {child.ageYears} anni e {child.ageMonths} mesi
          </li>
        ))}
      </ul>

      <h3 className="text-2xl mt-4">Appuntamenti</h3>
      <ul>
        {appointments?.filter((app) => app.clientId === client.id).map((app) => (
          <li key={app.id} className="border p-4 mb-2 rounded">
            <p><strong>Descrizione:</strong> {app.description}</p>
            <p><strong>Data:</strong> {new Date(app.date).toLocaleDateString()}</p>
            <button
              onClick={() => removeAppointment(app.id)}
              className="px-2 py-1 bg-red-500 text-white rounded"
            >
              Elimina Appuntamento
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ClientProfile;
