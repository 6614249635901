import React, { useContext, useState } from 'react';
import { ClientContext } from '../../context/ClientContext';

const AddClient = () => {
  const { addClient } = useContext(ClientContext);

  const [newClient, setNewClient] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    address: '',
    city: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validazione dei campi
    if (
      !newClient.name ||
      !newClient.surname ||
      !newClient.email ||
      !newClient.phone ||
      !newClient.address ||
      !newClient.city
    ) {
      setErrorMessage('Tutti i campi sono obbligatori.');
      setSuccessMessage('');
      return;
    }

    // Salva il cliente
    addClient(newClient);

    // Messaggio di successo
    setSuccessMessage('Cliente aggiunto con successo!');
    setErrorMessage('');

    // Reimposta il modulo
    setNewClient({
      name: '',
      surname: '',
      email: '',
      phone: '',
      address: '',
      city: '',
    });
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h3 className="text-xl font-semibold mb-4">Aggiungi Cliente</h3>

      {/* Messaggi di successo o errore */}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      <input
        type="text"
        name="name"
        value={newClient.name}
        onChange={handleInputChange}
        placeholder="Nome"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="surname"
        value={newClient.surname}
        onChange={handleInputChange}
        placeholder="Cognome"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="email"
        name="email"
        value={newClient.email}
        onChange={handleInputChange}
        placeholder="Email"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="phone"
        value={newClient.phone}
        onChange={handleInputChange}
        placeholder="Telefono"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="address"
        value={newClient.address}
        onChange={handleInputChange}
        placeholder="Indirizzo"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="city"
        value={newClient.city}
        onChange={handleInputChange}
        placeholder="Città"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
        Aggiungi Cliente
      </button>
    </form>
  );
};

export default AddClient;
