import React, { useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import { AppointmentContext } from '../context/AppointmentContext';
import { ClientContext } from '../context/ClientContext';
import { PackageContext } from '../context/PackageContext';
import { FinanceContext } from '../context/FinanceContext';

const localizer = momentLocalizer(moment);

function Dashboard() {
  const { appointments } = useContext(AppointmentContext);
  const { clients } = useContext(ClientContext);
  const { packages } = useContext(PackageContext);
  const { payments } = useContext(FinanceContext);

  const combineDateAndTime = (date, time) => {
    const dateTimeString = `${moment(date).format('YYYY-MM-DD')}T${time || '00:00'}`;
    return moment(dateTimeString, 'YYYY-MM-DDTHH:mm').toDate();
  };

  const enrichedAppointments = appointments.map((appointment) => {
    const client = clients.find((c) => c.id === appointment.clientId);
    const pkg = packages.find((p) => p.id === parseInt(appointment.packageId, 10));

    const startDateTime = combineDateAndTime(appointment.date, appointment.time);

    return {
      ...appointment,
      clientName: client ? `${client.name} ${client.surname}` : 'Cliente sconosciuto',
      service: pkg ? `${pkg.name} (${pkg.type})` : 'Pacchetto non specificato',
      start: startDateTime,
      end: moment(startDateTime).add(1, 'hours').toDate(),
    };
  });

  const calculateRevenue = (range) => {
    const now = moment();
    return payments
      .filter((payment) => {
        const paymentDate = moment(payment.date);
        switch (range) {
          case 'day':
            return paymentDate.isSame(now, 'day');
          case 'week':
            return paymentDate.isSame(now, 'week');
          case 'month':
            return paymentDate.isSame(now, 'month');
          default:
            return false;
        }
      })
      .reduce((total, payment) => total + (payment.amount - (payment.remainingAmount || 0)), 0);
  };

  const pendingPayments = payments
    .filter((payment) => payment.remainingAmount > 0)
    .map((payment) => {
      const client = clients.find((c) => c.id === payment.clientId);
      return {
        clientName: client ? `${client.name} ${client.surname}` : 'Cliente sconosciuto',
        remainingAmount: payment.remainingAmount,
      };
    });

  const calculateNewClients = (range) => {
    const now = moment();
    return clients.filter((client) => {
      const clientCreationDate = moment(client.createdAt); // Presuppone un campo `createdAt` nei clienti
      switch (range) {
        case 'week':
          return clientCreationDate.isSame(now, 'week');
        case 'month':
          return clientCreationDate.isSame(now, 'month');
        default:
          return false;
      }
    });
  };

  const recurringClients = clients
    .map((client) => {
      const clientAppointments = appointments.filter((appt) => appt.clientId === client.id);
      return {
        clientName: `${client.name} ${client.surname}`,
        appointmentCount: clientAppointments.length,
        services: clientAppointments.map((appt) => {
          const pkg = packages.find((p) => p.id === parseInt(appt.packageId, 10));
          return pkg ? `${pkg.name} (${pkg.type})` : 'Non specificato';
        }),
      };
    })
    .filter((client) => client.appointmentCount > 1);

  const newClientsThisMonth = calculateNewClients('month');
  const newClientsThisWeek = calculateNewClients('week');

  const serviceCounts = packages.map((pkg) => {
    const count = appointments.filter((appt) => parseInt(appt.packageId, 10) === pkg.id).length;
    return { name: `${pkg.name} (${pkg.type})`, count };
  });

  const chartData = {
    labels: serviceCounts.map((service) => service.name),
    datasets: [
      {
        data: serviceCounts.map((service) => service.count),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  const calculateMonthlyPerformance = () => {
    const now = moment();
    const currentMonth = now.month();
    const previousMonth = now.subtract(1, 'month').month();

    const currentMonthRevenue = payments
      .filter((payment) => moment(payment.date).month() === currentMonth)
      .reduce((total, payment) => total + (payment.amount - (payment.remainingAmount || 0)), 0);

    const previousMonthRevenue = payments
      .filter((payment) => moment(payment.date).month() === previousMonth)
      .reduce((total, payment) => total + (payment.amount - (payment.remainingAmount || 0)), 0);

    return { currentMonthRevenue, previousMonthRevenue };
  };

  const { currentMonthRevenue, previousMonthRevenue } = calculateMonthlyPerformance();

  const monthlyPerformanceData = {
    labels: ['Mese Precedente', 'Mese Corrente'],
    datasets: [
      {
        label: 'Incassi Mensili',
        data: [previousMonthRevenue, currentMonthRevenue],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const unconfirmedAppointments = enrichedAppointments.filter(
    (appt) => appt.status === 'non confermato'
  );

  return (
    <div className="p-6">
      <h1 className="text-3xl font-semibold mb-6">Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Prossimi Appuntamenti */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Prossimi Appuntamenti</h2>
          <ul className="divide-y divide-gray-200">
            {enrichedAppointments
              .filter((appt) => appt.start >= new Date())
              .sort((a, b) => a.start - b.start)
              .slice(0, 5)
              .map((appt) => (
                <li key={appt.id} className="py-2">
                  <p className="text-lg font-medium">{appt.clientName}</p>
                  <p>Data: {moment(appt.start).format('DD/MM/YYYY')}</p>
                  <p>Orario: {moment(appt.start).format('HH:mm')}</p>
                  <p>Servizio: {appt.service}</p>
                </li>
              ))}
          </ul>
        </div>

        {/* Calendario Sintetico */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1 lg:col-span-2">
          <h2 className="text-xl font-semibold mb-4">Calendario Sintetico</h2>
          <Calendar
            localizer={localizer}
            events={enrichedAppointments.map((appt) => ({
              title: `${appt.clientName} - ${appt.service}`,
              start: appt.start,
              end: appt.end,
            }))}
            defaultView="month"
            style={{ height: 400 }}
            popup
          />
        </div>

{/* Appuntamenti Non Confermati */}
<div className="p-4 bg-white shadow rounded-lg col-span-1">
  <h2 className="text-xl font-semibold mb-4">Appuntamenti Non Confermati</h2>
  <ul className="divide-y divide-gray-200">
    {unconfirmedAppointments.map((appt) => {
      const client = clients.find((c) => c.id === appt.clientId);

      const whatsappMessage = encodeURIComponent(
        `Ciao ${client?.name || 'Cliente'}, ti ricordiamo il tuo appuntamento per il servizio ${appt.service} il giorno ${moment(appt.start).format('DD/MM/YYYY')} alle ore ${moment(appt.start).format('HH:mm')}. Ti preghiamo di confermare la tua presenza. Grazie!`
      );

      const whatsappLink = `https://wa.me/${client?.phone}?text=${whatsappMessage}`;

      return (
        <li key={appt.id} className="py-2">
          <div>
            <p className="text-lg font-medium">{appt.clientName}</p>
            <p>
              <strong>Data:</strong> {moment(appt.start).format('DD/MM/YYYY')}
            </p>
            <p>
              <strong>Orario:</strong> {moment(appt.start).format('HH:mm')}
            </p>
            <p>
              <strong>Servizio:</strong> {appt.service}
            </p>
          </div>
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 inline-block text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded"
          >
            Invia Promemoria WhatsApp
          </a>
        </li>
      );
    })}
  </ul>
  {unconfirmedAppointments.length === 0 && (
    <p className="text-gray-500">Nessun appuntamento non confermato.</p>
  )}
</div>

        {/* Distribuzione Servizi */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Distribuzione dei Servizi</h2>
          <Pie data={chartData} />
        </div>

        {/* Statistiche di Fatturato */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Introiti Recenti</h2>
          <p>Oggi: €{calculateRevenue('day').toFixed(2)}</p>
          <p>Questa Settimana: €{calculateRevenue('week').toFixed(2)}</p>
          <p>Questo Mese: €{calculateRevenue('month').toFixed(2)}</p>
        </div>

        {/* Pagamenti Pendenti */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Pagamenti Pendenti</h2>
          <ul>
            {pendingPayments.map((payment, index) => (
              <li key={index}>
                {payment.clientName}: €{payment.remainingAmount.toFixed(2)}
              </li>
            ))}
          </ul>
          {pendingPayments.length === 0 && <p>Nessun pagamento pendente.</p>}
        </div>

        {/* Clienti Ricorrenti */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Clienti Ricorrenti</h2>
          <ul>
            {recurringClients.map((client, index) => (
              <li key={index}>
                <p className="font-medium">{client.clientName}</p>
                <p>Appuntamenti: {client.appointmentCount}</p>
                <p>Servizi: {client.services.join(', ')}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Nuovi Clienti */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1">
          <h2 className="text-xl font-semibold mb-4">Nuovi Clienti</h2>
          <p>Questa settimana: {newClientsThisWeek.length}</p>
          <p>Questo mese: {newClientsThisMonth.length}</p>
        </div>

        {/* Performance Mensile */}
        <div className="p-4 bg-white shadow rounded-lg col-span-1 md:col-span-2">
          <h2 className="text-xl font-semibold mb-4">Performance Mensile</h2>
          <Bar data={monthlyPerformanceData} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
