import React, { useContext } from 'react';
import { ClientContext } from '../../context/ClientContext';
import { useNavigate } from 'react-router-dom';

const ClientList = () => {
  const { clients, removeClient } = useContext(ClientContext); // Aggiunta della funzione `removeClient`
  const navigate = useNavigate();

  const handleAddClient = () => {
    navigate('/aggiungi-cliente'); // Naviga alla pagina di aggiunta cliente
  };

  const handleEditClient = (id) => {
    navigate(`/modifica-cliente/${id}`); // Naviga alla pagina di modifica cliente
  };

  const handleViewClient = (id) => {
    navigate(`/clienti/${id}`); // Naviga all'anagrafica del cliente
  };

  const handleDeleteClient = (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questo cliente?')) {
      removeClient(id); // Rimuove il cliente dal contesto
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Lista Clienti</h2>
      <button
        onClick={handleAddClient}
        className="mb-4 px-4 py-2 bg-blue-600 text-white rounded"
      >
        Aggiungi Cliente
      </button>

      {/* Mostra i clienti salvati */}
      {clients.length > 0 ? (
        <ul className="divide-y divide-gray-200">
          {clients.map((client) => (
            <li key={client.id} className="py-2 flex justify-between items-center">
              <span>
                {client.name} {client.surname}
              </span>
              <div className="flex gap-2">
                <button
                  onClick={() => handleViewClient(client.id)}
                  className="px-2 py-1 bg-green-500 text-white rounded"
                >
                  Visualizza
                </button>
                <button
                  onClick={() => handleEditClient(client.id)}
                  className="px-2 py-1 bg-yellow-500 text-white rounded"
                >
                  Modifica
                </button>
                <button
                  onClick={() => handleDeleteClient(client.id)}
                  className="px-2 py-1 bg-red-500 text-white rounded"
                >
                  Elimina
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">Nessun cliente trovato. Aggiungi un nuovo cliente!</p>
      )}
    </div>
  );
};

export default ClientList;
