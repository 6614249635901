import React, { createContext, useState, useEffect } from 'react';

export const FinanceContext = createContext();

export const FinanceProvider = ({ children }) => {
  const [payments, setPayments] = useState(() => {
    const savedPayments = localStorage.getItem('payments');
    return savedPayments ? JSON.parse(savedPayments) : [];
  });

  useEffect(() => {
    localStorage.setItem('payments', JSON.stringify(payments));
  }, [payments]);

  const addPayment = (payment) => {
    const newPayment = {
      id: Date.now(),
      remainingAmount: payment.amount,
      history: [],
      ...payment,
    };
    setPayments((prev) => [...prev, newPayment]);
  };

  const updatePayment = (updatedPayment) => {
    setPayments((prev) =>
      prev.map((payment) => (payment.id === updatedPayment.id ? { ...payment, ...updatedPayment } : payment))
    );
  };

  const removePaymentByAppointmentId = (appointmentId) => {
    setPayments((prev) => prev.filter((payment) => payment.appointmentId !== appointmentId));
  };

  return (
    <FinanceContext.Provider value={{ payments, addPayment, updatePayment, removePaymentByAppointmentId }}>
      {children}
    </FinanceContext.Provider>
  );
};
