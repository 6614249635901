import React, { createContext, useState, useEffect } from 'react';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clients, setClients] = useState(() => {
    const savedClients = localStorage.getItem('clients');
    return savedClients ? JSON.parse(savedClients) : [];
  });

  useEffect(() => {
    localStorage.setItem('clients', JSON.stringify(clients));
  }, [clients]);

  const addClient = (client) => {
    setClients((prev) => [
      ...prev,
      { id: Date.now(), children: [], ...client },
    ]);
  };

  const updateClient = (id, updatedFields) => {
    setClients((prev) =>
      prev.map((client) =>
        client.id === parseInt(id, 10)
          ? { ...client, ...updatedFields, children: updatedFields.children || client.children }
          : client
      )
    );
  };

  const removeClient = (id) => {
    setClients((prev) => prev.filter((client) => client.id !== parseInt(id, 10)));
  };

  return (
    <ClientContext.Provider value={{ clients, addClient, updateClient, removeClient }}>
      {children}
    </ClientContext.Provider>
  );
};
