import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { DashboardProvider } from './context/DashboardContext';
import AddClient from './components/Clients/AddClient';
import { ClientProvider } from './context/ClientContext';
import { AppointmentProvider } from './context/AppointmentContext';
import { AlbumProvider } from './context/AlbumContext';
import { FinanceProvider } from './context/FinanceContext';
import { EquipmentProvider } from './context/EquipmentContext';
import { ContractsProvider } from './context/ContractsContext';
import { MarketingProvider } from './context/MarketingContext';
import { SettingsProvider } from './context/SettingsContext';
import { PackageProvider } from './context/PackageContext';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ClientList from './components/Clients/ClientList';
import Appointments from './components/Appointments';
import Portfolio from './components/Portfolio';
import Payments from './components/Payments';
import Equipment from './components/Equipment';
import Contracts from './components/Contracts';
import Marketing from './components/Marketing';
import Settings from './components/Settings';
import ClientProfile from './components/Clients/ClientProfile';
import EditClient from './components/Clients/EditClient'; // Importa EditClient

const isAuthenticated = () => {
  const token = localStorage.getItem('userToken');
  console.log('Token trovato:', token); // Debug
  return !!token;
};

function ProtectedRoute({ children }) {
  return isAuthenticated() ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <DashboardProvider>
      <ClientProvider>
        <FinanceProvider>
          <AppointmentProvider>
            <AlbumProvider>
              <EquipmentProvider>
                <ContractsProvider>
                  <MarketingProvider>
                    <SettingsProvider>
                      <PackageProvider>
                        <Router>
                          <Routes>
                            {/* Percorso Login */}
                            <Route
                              path="/login"
                              element={<div>Caricamento...</div>}
                            />
                            {/* Rotte protette */}
                            <Route
                              path="*"
                              element={
                                <ProtectedRoute>
                                  <div className="flex">
                                    <Sidebar />
                                    <div className="flex-grow p-6">
                                      <Routes>
                                        <Route path="/" element={<Dashboard />} />
                                        <Route path="/clienti" element={<ClientList />} />
                                        <Route path="/aggiungi-cliente" element={<AddClient />} />
                                        <Route path="/appuntamenti" element={<Appointments />} />
                                        <Route path="/portfolio" element={<Portfolio />} />
                                        <Route path="/pagamenti" element={<Payments />} />
                                        <Route path="/attrezzatura" element={<Equipment />} />
                                        <Route path="/contratti" element={<Contracts />} />
                                        <Route path="/marketing" element={<Marketing />} />
                                        <Route path="/impostazioni" element={<Settings />} />
                                        <Route path="/clienti/:clientId" element={<ClientProfile />} />
                                        <Route path="/modifica-cliente/:clientId" element={<EditClient />} />
                                      </Routes>
                                    </div>
                                  </div>
                                </ProtectedRoute>
                              }
                            />
                          </Routes>
                        </Router>
                      </PackageProvider>
                    </SettingsProvider>
                  </MarketingProvider>
                </ContractsProvider>
              </EquipmentProvider>
            </AlbumProvider>
          </AppointmentProvider>
        </FinanceProvider>
      </ClientProvider>
    </DashboardProvider>
  );
}

export default App;
