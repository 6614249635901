import React, { createContext, useState, useEffect, useContext } from 'react';
import { FinanceContext } from './FinanceContext'; // Importa il contesto dei pagamenti

export const AppointmentContext = createContext();

export const AppointmentProvider = ({ children }) => {
  const [appointments, setAppointments] = useState(() => {
    const savedAppointments = localStorage.getItem('appointments');
    return savedAppointments ? JSON.parse(savedAppointments) : [];
  });

  const { removePaymentByAppointmentId } = useContext(FinanceContext);

  useEffect(() => {
    localStorage.setItem('appointments', JSON.stringify(appointments));
  }, [appointments]);

  const addAppointment = (appointment) => {
    const newAppointment = {
      id: Date.now(),
      time: appointment.time || '00:00', // Aggiunge un orario predefinito se non specificato
      ...appointment,
    };
    setAppointments((prevAppointments) => [...prevAppointments, newAppointment]);
    return newAppointment; // Restituisce l'appuntamento creato
  };

  const updateAppointment = (id, updatedFields) => {
    setAppointments((prevAppointments) =>
      prevAppointments.map((appointment) =>
        appointment.id === id ? { ...appointment, ...updatedFields } : appointment
      )
    );
  };

  const removeAppointment = (id) => {
    console.log(`Rimuovo l'appuntamento con ID: ${id}`);
    const appointmentToRemove = appointments.find((app) => app.id === id);
    if (appointmentToRemove) {
      console.log(`Rimuovo il pagamento associato all'appuntamento con ID: ${id}`);
      removePaymentByAppointmentId(id);
    }
    setAppointments((prevAppointments) =>
      prevAppointments.filter((appointment) => appointment.id !== id)
    );
  };

  return (
    <AppointmentContext.Provider
      value={{ appointments, addAppointment, updateAppointment, removeAppointment }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
