// src/components/ClientSearch.js
import React from 'react';

function ClientSearch({ clients, setQuery }) {
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setQuery(query);
  };

  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Cerca cliente..."
        onChange={handleSearch}
        className="p-2 border border-gray-300 rounded w-full"
      />
    </div>
  );
}

export default ClientSearch;
