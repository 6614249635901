import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ClientContext } from '../../context/ClientContext';

const EditClient = () => {
  const { clients, updateClient } = useContext(ClientContext);
  const { clientId } = useParams();
  const navigate = useNavigate();

  const [clientData, setClientData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    children: [],
  });

  useEffect(() => {
    const client = clients.find((c) => c.id === parseInt(clientId, 10));
    if (client) {
      setClientData(client);
    }
  }, [clientId, clients]);

  const calculateAge = (birthDate) => {
    const now = new Date();
    const birth = new Date(birthDate);
    const ageYears = now.getFullYear() - birth.getFullYear();
    const ageMonths = now.getMonth() - birth.getMonth();
    return {
      ageYears: ageMonths < 0 ? ageYears - 1 : ageYears,
      ageMonths: (ageMonths + 12) % 12,
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...clientData.children];
    if (field === 'birthDate') {
      const age = calculateAge(value);
      updatedChildren[index] = { ...updatedChildren[index], ...age, [field]: value };
    } else {
      updatedChildren[index] = { ...updatedChildren[index], [field]: value };
    }
    setClientData((prev) => ({ ...prev, children: updatedChildren }));
  };

  const handleAddChild = () => {
    setClientData((prev) => ({
      ...prev,
      children: [
        ...prev.children,
        { name: '', birthDate: '', ageYears: 0, ageMonths: 0 },
      ],
    }));
  };

  const handleRemoveChild = (index) => {
    setClientData((prev) => ({
      ...prev,
      children: prev.children.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateClient(parseInt(clientId, 10), clientData);
    navigate('/clienti');
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <h3 className="text-xl font-semibold mb-4">Modifica Cliente</h3>
      <input
        type="text"
        name="name"
        value={clientData.name}
        onChange={handleInputChange}
        placeholder="Nome"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="surname"
        value={clientData.surname}
        onChange={handleInputChange}
        placeholder="Cognome"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="email"
        name="email"
        value={clientData.email}
        onChange={handleInputChange}
        placeholder="Email"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="phone"
        value={clientData.phone}
        onChange={handleInputChange}
        placeholder="Telefono"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="address"
        value={clientData.address}
        onChange={handleInputChange}
        placeholder="Indirizzo"
        required
        className="border p-2 rounded mb-2 w-full"
      />
      <input
        type="text"
        name="city"
        value={clientData.city}
        onChange={handleInputChange}
        placeholder="Città"
        required
        className="border p-2 rounded mb-2 w-full"
      />

      <h3 className="text-lg font-semibold mb-2">Figli</h3>
      {clientData.children.map((child, index) => (
        <div key={index} className="mb-4 border p-4 rounded bg-gray-50">
          <input
            type="text"
            placeholder="Nome"
            value={child.name}
            onChange={(e) => handleChildChange(index, 'name', e.target.value)}
            className="border p-2 rounded mb-2 w-full"
          />
          <input
            type="date"
            placeholder="Data di Nascita"
            value={child.birthDate || ''}
            onChange={(e) => handleChildChange(index, 'birthDate', e.target.value)}
            className="border p-2 rounded mb-2 w-full"
          />
          <p>
            Età: {child.ageYears || 0} anni e {child.ageMonths || 0} mesi
          </p>
          <button
            type="button"
            onClick={() => handleRemoveChild(index)}
            className="px-4 py-2 bg-red-500 text-white rounded"
          >
            Rimuovi Figlio
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddChild}
        className="px-4 py-2 bg-green-500 text-white rounded mb-4"
      >
        Aggiungi Figlio
      </button>
      <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
        Salva Modifiche
      </button>
    </form>
  );
};

export default EditClient;
