import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AppointmentContext } from '../context/AppointmentContext';
import { ClientContext } from '../context/ClientContext';
import { PackageContext } from '../context/PackageContext';
import { FinanceContext } from '../context/FinanceContext';

const localizer = momentLocalizer(moment);

function Appointments() {
  const { appointments, addAppointment, updateAppointment, removeAppointment } =
    useContext(AppointmentContext);
  const { clients } = useContext(ClientContext);
  const { packages } = useContext(PackageContext);
  const { addPayment } = useContext(FinanceContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [description, setDescription] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [filteredClients, setFilteredClients] = useState(clients);
  const [showClientList, setShowClientList] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [appointmentStatus, setAppointmentStatus] = useState('non confermato'); // Nuovo stato
  const [isEditing, setIsEditing] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [clientError, setClientError] = useState(false);

  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  useEffect(() => {
    if (isEditing && appointmentId) {
      const appointment = appointments.find((app) => app.id === appointmentId);
      if (appointment) {
        setDescription(appointment.description);
        const client = clients.find((c) => c.id === appointment.clientId);
        setSelectedClient(client ? `${client.name} ${client.surname}` : '');
        setSelectedPackage(appointment.packageId);
        setSelectedDate(new Date(appointment.date));
        setSelectedTime(appointment.time || '');
        setAppointmentStatus(appointment.status || 'non confermato'); // Imposta lo stato
      }
    } else {
      resetForm();
    }
  }, [isEditing, appointmentId, appointments, clients]);

  const handleClientSearch = (e) => {
    const search = e.target.value;
    setSelectedClient(search);
    const lowerSearch = search.toLowerCase();
    if (lowerSearch) {
      const matches = clients.filter((c) =>
        `${c.name} ${c.surname}`.toLowerCase().includes(lowerSearch)
      );
      setFilteredClients(matches);
      setShowClientList(matches.length > 0);
      setClientError(matches.length === 0);
    } else {
      setFilteredClients(clients);
      setShowClientList(false);
      setClientError(false);
    }
  };

  const handleClientSelect = (client) => {
    setSelectedClient(`${client.name} ${client.surname}`);
    setShowClientList(false);
    setClientError(false);
  };

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    resetForm();
    setIsEditing(false);
  };

  const handleSelectEvent = (event) => {
    setIsEditing(true);
    setAppointmentId(event.id);
  };

  const resetForm = () => {
    setDescription('');
    setSelectedClient('');
    setSelectedPackage('');
    setSelectedTime('');
    setAppointmentId(null);
    setAppointmentStatus('non confermato'); // Reset dello stato
    setClientError(false);
    setFilteredClients(clients);
    setShowClientList(false);
  };

const handleSubmit = (e) => {
    e.preventDefault();

    const client = clients.find(
        (c) => `${c.name} ${c.surname}`.toLowerCase() === selectedClient.toLowerCase()
    );
    if (!client) {
        setClientError(true);
        return;
    }

    const selectedPkg = packages.find((pkg) => pkg.id === parseInt(selectedPackage, 10));
    if (!selectedPkg) {
        alert('Seleziona un pacchetto valido.');
        return;
    }

    if (description && selectedDate && selectedTime) {
        const newAppointment = {
            clientId: client.id,
            description,
            date: selectedDate.toISOString(),
            time: selectedTime,
            packageId: selectedPackage,
            status: appointmentStatus,
        };

        if (isEditing && appointmentId) {
            // Aggiorna l'appuntamento esistente
            updateAppointment(appointmentId, newAppointment);
        } else {
            // Aggiunge un nuovo appuntamento
            const createdAppointment = addAppointment(newAppointment);

            // Aggiunge un pagamento associato al nuovo appuntamento
            addPayment({
                clientId: client.id,
                appointmentId: createdAppointment.id,
                packageId: selectedPackage,
                amount: selectedPkg.price,
                remainingAmount: selectedPkg.price,
                status: 'Non Pagato',
                date: selectedDate.toISOString(),
            });
        }

        resetForm();
        setIsEditing(false);
    }
};


  const handleDelete = (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questo appuntamento?')) {
      removeAppointment(id);
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Appuntamenti</h2>
      <Calendar
        localizer={localizer}
        events={appointments.map((app) => {
          const startDateTime = new Date(app.date);
          const [hours, minutes] = app.time.split(':');
          startDateTime.setHours(hours, minutes);

          const endDateTime = new Date(startDateTime);
          endDateTime.setMinutes(endDateTime.getMinutes() + 30);

          return {
            id: app.id,
            title: `${clients.find((c) => c.id === app.clientId)?.name || 'Sconosciuto'} - ${
              app.status || 'non confermato'
            }`,
            start: startDateTime,
            end: endDateTime,
          };
        })}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        style={{ height: 500 }}
      />

      <form onSubmit={handleSubmit} className="mt-4">
        <input
          type="text"
          value={selectedClient}
          onChange={handleClientSearch}
          placeholder="Scrivi il nome del cliente"
          className="p-2 border rounded w-full mb-2"
          required
        />
        {showClientList && (
          <ul className="border p-2 bg-white shadow-md max-h-40 overflow-auto">
            {filteredClients.map((client) => (
              <li
                key={client.id}
                onClick={() => handleClientSelect(client)}
                className="p-1 hover:bg-gray-100 cursor-pointer"
              >
                {client.name} {client.surname}
              </li>
            ))}
          </ul>
        )}
        {clientError && (
          <p className="text-red-500 text-sm">Cliente non trovato. Controlla il nome inserito.</p>
        )}

        <select
          value={selectedPackage}
          onChange={(e) => setSelectedPackage(e.target.value)}
          required
          className="p-2 border rounded w-full mb-2"
        >
          <option value="">Seleziona Pacchetto</option>
          {packages.map((pkg) => (
            <option key={pkg.id} value={pkg.id}>
              {pkg.name} ({pkg.type}) - €{pkg.price}
            </option>
          ))}
        </select>

        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Descrizione"
          className="p-2 border rounded w-full mb-2"
          required
        />

        <input
          type="date"
          value={selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : ''}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          className="p-2 border rounded w-full mb-2"
          required
        />

        <input
          type="time"
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          className="p-2 border rounded w-full mb-2"
          required
        />

        <select
          value={appointmentStatus}
          onChange={(e) => setAppointmentStatus(e.target.value)}
          className="p-2 border rounded w-full mb-2"
        >
          <option value="confermato">Confermato</option>
          <option value="non confermato">Non Confermato</option>
        </select>

        <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
          {isEditing ? 'Aggiorna Appuntamento' : 'Aggiungi Appuntamento'}
        </button>
      </form>
    </div>
  );
}

export default Appointments;
